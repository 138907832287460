import React, { useContext, useState } from 'react';
import axios from 'axios';
import { 
  Container,
  Dialog, 
  DialogTitle,
  Button,
} from '@mui/material';
import { getJWTToken } from '../utils/utils';
import errorResponse from '../responses/errorResponse';
import { interfaces } from '../interfaces';
import deleteRecipeRequest from '../requests/deleteRecipeRequest';
import { alertMessages, AlertContext } from '../index';
import { getEmail } from '../utils/utils';


function AreYouSurePopup({ 
    isOpen,
    onClose,
    mealId
  }: interfaces.AreYouSurePopupProps) {

  const {
    timedAlert
  } = useContext(AlertContext)

  return (
    <Dialog open={isOpen} onClose={onClose} style={{ width: '100%' }}>
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DialogTitle>Are you sure you want to delete this meal?</DialogTitle>
            
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '10px' }}>
              <Button variant="contained" style={{ backgroundColor: 'red', marginRight: '10px' }} onClick={async () => {
                await deleteRecipe(mealId, timedAlert, onClose);
              }}>
                Delete
              </Button>
              <Button variant="contained" onClick={onClose}>Cancel</Button>
            </div>
        </Container>
    </Dialog>
  );
}

async function deleteRecipe(id: string, timedAlert: (alert: interfaces.alert) => void, onClose: () => void) {
  const deleteRecipeReq: deleteRecipeRequest = new deleteRecipeRequest(id, getEmail());

  await axios.post(`${process.env.REACT_APP_BACKEND_URL}/deleterecipe`, JSON.stringify(deleteRecipeReq), {
    withCredentials: true,
    headers: {
      'Authorization': `Bearer ${getJWTToken()}`,
    }
  })
  .then(response => {
    timedAlert(alertMessages.recipeDeletedSuccessfully);
    onClose();
  })
  .catch(error => {
    const err: errorResponse = new errorResponse(error);
  })
}

export default AreYouSurePopup;
