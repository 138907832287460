import request from './request'

class removeFromUpcomingMealsRequest extends request {
  private idToRemove: string; 
  
  constructor(idToRemove: string) {
    super();
    this.idToRemove = idToRemove;
  }
}

export default removeFromUpcomingMealsRequest;