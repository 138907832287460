import response from './response'
import { AxiosResponse } from 'axios';
import { interfaces } from '../interfaces';

class getUsersFiltersResponse extends response {

    private usersFilters: Array<string>;

    constructor(response: AxiosResponse) {
        super(response.status);
        this.usersFilters = response.data.usersFilters;
    }

    getUsersFilters() {
        return this.usersFilters;
    }
}

export default getUsersFiltersResponse;
