import React, { useState, useContext } from 'react';
import { 
  Container,
  Dialog, 
  DialogTitle
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; 
import { interfaces } from '../interfaces';
import { PlanPageContext } from '../App';
import AddToGroceryCartPopup from './addToGroceryCart';

function CalendarPopup({
  isOpen, 
  onClose,
  meal,
  saveUpcomingMeal
}: interfaces.CalendarPopupProps) {

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [showIngredientPopup, setShowIngredientPopup] = useState<boolean>(false);

  const {
    onPlusClick
  } = useContext(PlanPageContext)

  const dateSelected = (date: Date | null) => {
    setSelectedDate(date);
  }

  const okSelected = () => {
    setDateOnQueuedMeal(onPlusClick, meal, selectedDate);
    setShowIngredientPopup(true);
    onClose();
  }

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} style={{ width: '100%' }}>
          <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <DialogTitle>Select a Date for this Meal:</DialogTitle>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDatePicker onChange={dateSelected} onAccept={okSelected} />
                  </LocalizationProvider>
          </Container>
      </Dialog>

      <AddToGroceryCartPopup 
        isOpen={showIngredientPopup}
        onClose={() => {
          setShowIngredientPopup(false);
          saveUpcomingMeal();
        }}
        meal={meal}
      />
    </>
  );
}

function setDateOnQueuedMeal(onPlusClick: (mealToAdd: interfaces.Meal) => void, meal: interfaces.Meal, date: Date | null) {
  meal.date = date;

  onPlusClick(meal);
}

export default CalendarPopup;