import response from './response'
import { AxiosResponse } from 'axios';
import { interfaces } from '../interfaces';

class getAllMealsResponse extends response {

    private allRecipes: Array<interfaces.Meal>

    constructor(response: AxiosResponse) {
        super(response.status);

        this.allRecipes = response.data.allRecipes;
    }

    getAllRecipes() {
        return this.allRecipes;
    }
}

export default getAllMealsResponse;
