import request from './request'

class loginUserRequest extends request {
  private email: string;
  private password: string;  
  
  constructor(email: string, password: string) {
      super();
      this.email = email;
      this.password = password;
    }
}

export default loginUserRequest