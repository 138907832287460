import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  CircularProgress, 
  Container, 
  Typography
} from '@mui/material';
import MealCard from './mealCard';
import { getJWTToken } from '../utils/utils';
import errorResponse from '../responses/errorResponse';
import { interfaces } from '../interfaces';
import getAllUsersRecipesResponse from '../responses/getAllUsersRecipesResponse';
  
export default function RecipeBookPage() {
  const [allUsersMeals, setAllUsersMeals] = useState<interfaces.Meal[]>([])
  const [loading, setLoading] = useState<boolean>(true);

  const getAllUsersRecipes = async () => {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getallmeals`, {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${getJWTToken()}`,
      }
    })
    .then(response => {
      const getAllUsersRecipesRes: getAllUsersRecipesResponse = new getAllUsersRecipesResponse(response);
      setAllUsersMeals(getAllUsersRecipesRes.getAllRecipes());
    })
    .catch(error => {
      const err: errorResponse = new errorResponse(error);
      // Display something
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getAllUsersRecipes();
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {
        !loading ? (
          <>
            <Container style={{ textAlign: 'center', marginTop: '80px' }}>
              <Typography variant="h5">Recipe Book</Typography>
            </Container>

            <Container style={{ marginTop: '20px', textAlign: 'center' }}>
              {allUsersMeals.map((meal) => (
                <MealCard 
                  meal={meal}
                  allowEdit={true}
                  allowDelete={true}
                />
              ))}
            </Container>
          </>
        ) : (
          <CircularProgress />
        )
      }
    </>
  );
}
