import { interfaces } from '../interfaces';
import response from './response'
import { AxiosResponse } from 'axios';

class getSharedMealsResponse extends response {

    private sharedMeals: Array<interfaces.recipe>;

    constructor(response: AxiosResponse) {
        super(response.status);
        this.sharedMeals = response.data.sharedMeals;
    }

    getSharedMeals() {
        return this.sharedMeals;
    }
}

export default getSharedMealsResponse;
