import response from './response'
import { AxiosError } from 'axios';

interface ErrorResponse {
    status: number;
    message: string;
}

class errorResponse extends response {

    private errorMessage: string | undefined;

    constructor(errorResponse: AxiosError<ErrorResponse>) {
        super(errorResponse.response?.data.status ?? 500);

        this.errorMessage = errorResponse.response?.data.message;
    }

    getMessage() {
        return this.errorMessage;
    }
}

export default errorResponse;
