import React, { useState, useContext } from 'react';
import { interfaces } from '../interfaces';
import axios from 'axios';
import { 
    Container,
    Dialog, 
    DialogTitle,
    Grid,
    TextField,
    Button
} from '@mui/material';
import addItemsToGroceryCartRequest from '../requests/addItemsToGroceryCartRequest';
import { getJWTToken } from '../utils/utils';
import errorResponse from '../responses/errorResponse';
import { AlertContext, alertMessages } from '..';


function AddGroceryItemPopup({
  isOpen, 
  onClose
}: interfaces.GroceryItemPopupProps) {

    const [IngredientsValue, setIngredientsValue] = useState<string[]>([]);

    const {
        timedAlert
    } = useContext(AlertContext);

    const handleButtonClick = async() => {

        const addItemsToCartReq: addItemsToGroceryCartRequest = new addItemsToGroceryCartRequest(IngredientsValue);

        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/additemstocart`, JSON.stringify(addItemsToCartReq), {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            }
        })
        .then(response => {
            // const removeFromGroceryListRes = new removeFromGroceryListResponse(response);
            setIngredientsValue([]);
            onClose();
            timedAlert(alertMessages.groceryItemsSavedSuccessfully);
        })
        .catch(error => {
            const err: errorResponse = new errorResponse(error);
            // Display something
        })
    }

  return (
    <>
        <Dialog open={isOpen} onClose={onClose} style={{ width: '100%' }}>
            <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <DialogTitle>Enter the items you would like to add to your cart:</DialogTitle>

                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline"
                        label="Ingredients"
                        value={IngredientsValue.join('\n')}
                        onChange={(e) => setIngredientsValue(e.target.value.split('\n'))}
                        multiline
                        rows={4}
                        helperText="*Enter ONE Item per Line*"
                        fullWidth
                    />
                </Grid>
            </Container>

            <Container style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                <Button variant="contained" onClick={handleButtonClick} >
                    Save Changes
                </Button>
            </Container>
        </Dialog>
    </>
  );
}

export default AddGroceryItemPopup;
