import React, { useContext, useState } from 'react';
import {
  Container,
  Dialog,
  Button, AlertColor
} from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarPopup from './calendarPopup';
import { interfaces } from '../interfaces';
import EditRecipePage from './editRecipePage';
import AddToGroceryCartPopup from './addToGroceryCart';
import ShareIcon from '@mui/icons-material/Share';
import TimedPopup from './timedPopup';
import { PlanPageContext, HomePageContext } from '../App';
import removeFromUpcomingMealsRequest from '../requests/removeFromUpcomingMealsRequest';
import { getJWTToken } from '../utils/utils';
import errorResponse from '../responses/errorResponse';
import { SharePageContext } from '../App';
import ShareMealPopup from './shareMealPopup';
import DeleteIcon from '@mui/icons-material/Delete';
import {AlertContext, alertMessages} from "../index";
import saveUpcomingMealRequest from '../requests/saveUpcomingMealRequest';
import AreYouSurePopup from './areYouSurePopup';

// MOVE THESE BACK (FOR SOME REASON TS COULDN"T FIND THEM IN THE FILE)
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

interface alert {
  type: AlertColor,
  title: string,
  content: string
}

interface MealCardProps {
  meal: Meal
  onPlusClick?: ((id: string) => void);
  onMinusClick?: (id: string) => void;
  handleCheckBoxClicked?: (ingredient: string, mealId: string) => void;
  onShareClick?: (id: string) => void;
  allowEdit?: boolean
  allowShare?: boolean
  allowQueueAndUnqueue?: boolean
  allowRemoveFromUpcomingMeals?: boolean
  allowAcceptReject?: boolean
  allowDelete?: boolean
}

interface Meal {
  filters: string[]
  recipe: recipe
  added: boolean
  date: Date | null
}

interface recipe {
  recipeName: string,
  ingredients: string[],
  directions: string,
  recipeId: string
  calories: string
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MealCard({ 
  meal,
  allowEdit,
  allowShare,
  allowQueueAndUnqueue,
  allowRemoveFromUpcomingMeals,
  allowAcceptReject,
  allowDelete
  }: MealCardProps) {

  const [expanded, setExpanded] = useState<boolean>(false);
  const [showCalendarPopup, setShowCalendarPopup] = useState<boolean>(false);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showAreYouSurePopup, setShowAreYouSurePopup] = useState<boolean>(false);
  const [showIngredientPopup, setShowIngredientPopup] = useState<boolean>(false);
  const [showTemporarySuccessPopup, setShowTemporatySuccessPopup] = useState<boolean>(false);

  const {
    onPlusClick,
    queuedMeal,
    ingredients
  } = useContext(PlanPageContext);

  const {
    timedAlert
  } = useContext(AlertContext)

  const {
    upcomingMeals,
    setUpcomingMeals,
    acceptMeal,
    rejectMeal
  } = useContext(HomePageContext);

  const {
    showSharePopup,
    setShowSharePopup,
    setMealToShare,
    mealToShare
  } = useContext(SharePageContext);

  const handleShareClick = () => {
    setMealToShare(meal.recipe.recipeId)
    setShowSharePopup(true)
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openCalendarPopup = () => {
    setShowCalendarPopup(true);
  }

  const saveUpcomingMeal = async() => {
    const groceryIngredients: string[] = ingredients;
    const saveUpcomingMealreq: saveUpcomingMealRequest = new saveUpcomingMealRequest(queuedMeal, groceryIngredients);
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/saveupcomingmeals`, JSON.stringify(saveUpcomingMealreq), {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${getJWTToken()}`,
        }
    }).then(response => {
      timedAlert(alertMessages.mealAddedToUpcomingMeals)
    })
    .catch(error => {
        const err: errorResponse = new errorResponse(error);
        // Display something
    })
  }

  const mealImage = mealNameToImage(meal.recipe.recipeName);

  return (
    <>
      <Card sx={{ maxWidth: 345, margin: 'auto', textAlign: 'center', marginBottom: '15px' }}>
      <CardHeader
        title={`${meal.recipe.recipeName}`}
        subheader={ meal.date ? `${new Date(meal.date).toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' })}` : '' }
      />
      <CardMedia
        component="img"
        height="194"
        image={mealImage}
        alt="Paella dish"
      />
      <CardContent>
        {
          meal.filters ? (
            <Filters 
              filters={meal.filters}
            />
          ) : (
            <></>
          )
        }

      </CardContent>
      <CardActions disableSpacing>
        { allowQueueAndUnqueue && 
          <IconButton aria-label="add to queue">          
            <AddIcon fontSize='large' style={{ color:'green' }} onClick={async () => {
              onPlusClick(meal);

              setShowIngredientPopup(true);
            }}/>
          </IconButton>
        }

        { allowRemoveFromUpcomingMeals && <IconButton aria-label="add to queue">
          {(
            <RemoveIcon fontSize='large' style={{ color:'red' }} onClick={() => removeFromUpcomingMeals(upcomingMeals, setUpcomingMeals, meal)}/>
          )}
          </IconButton>
        }

        { allowAcceptReject &&
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button variant="contained" style={{ marginBottom: '10px', marginRight: '5px', backgroundColor: '#06c98f', color: 'white' }} onClick={() => {acceptMeal(meal.recipe.recipeId)}}>Accept Meal!</Button>
            <Button variant="contained" style={{ marginBottom: '10px', marginLeft: '5px', backgroundColor: '#FF6961', color: 'white'  }} onClick={() => {rejectMeal(meal.recipe.recipeId)}}>Reject Meal</Button>
          </div>
        }

        { allowEdit &&
          <IconButton >
            <EditIcon onClick={() => setShowEditPopup(true)}/>
          </IconButton>
        }

        { allowDelete &&
            <IconButton >
              <DeleteIcon onClick={() => setShowAreYouSurePopup(true)}/>
            </IconButton>
        }

        { allowQueueAndUnqueue && 
          <IconButton>
            <CalendarMonthIcon onClick={openCalendarPopup}/>
          </IconButton>
        }

        { allowShare && 
          <IconButton>
            <ShareIcon onClick={handleShareClick} />
          </IconButton>
        }

        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>

          <Ingredients ingredients={meal.recipe.ingredients} />
          
          <Directions directions={meal.recipe.directions} />

          {
            meal.recipe.calories ? (
              <Calories calories={meal.recipe.calories} />
            ) : (
              <></>
            )
          }

        </CardContent>
      </Collapse>
    </Card>

    <CalendarPopup
      isOpen={showCalendarPopup}
      onClose={() => setShowCalendarPopup(false)}
      meal={meal}
      saveUpcomingMeal={saveUpcomingMeal}
    />

    <EditMealPopup
      isOpen={showEditPopup}
      onClose={() => setShowEditPopup(false)}
      meal={meal}
    />

    <AddToGroceryCartPopup 
      isOpen={showIngredientPopup}
      onClose={async () => {
        setShowIngredientPopup(false);
        await saveUpcomingMeal();
      }}
      meal={meal}
    />

    <AreYouSurePopup
      isOpen={showAreYouSurePopup}
      onClose={() => setShowAreYouSurePopup(false)}
      mealId={meal.recipe.recipeId}
    />

    <ShareMealPopup
      isOpen={showSharePopup}
      onClose={() => setShowSharePopup(false)}
      mealId={mealToShare}
    />

    { showTemporarySuccessPopup && <TimedPopup /> }
    </>
  );
}

interface EditMealPopupProps {
  isOpen: boolean
  onClose: () => void
  meal: Meal
}

function EditMealPopup({
  isOpen,
  onClose,
  meal
  }: EditMealPopupProps) {

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} style={{ width: '100%' }}>
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
            <EditRecipePage
              meal={meal}
              onClose={onClose}
            />
        </Container>
      </Dialog>
    </>
  )
}

function Filters({ filters }: interfaces.FiltersProps) {

  const formattedString = filters.join(' | ');
  
  if (formattedString == '') {
    return <></>
  }

  return (
    <>
      <Typography variant="body2">
        Labels:
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {formattedString}
      </Typography>
    </>
  )
}

function Ingredients({ ingredients }: interfaces.IngredientsProps) {
  return (
    <>
      <Typography variant="h6" fontWeight="bold" paragraph>
        Ingredients:
      </Typography>
  
      {ingredients.map((ingredient: string) => (
        <Typography paragraph>
          {ingredient}
        </Typography>
      ))}
    </>
  )
}

function Directions({ directions }: interfaces.DirectionProps) {
  const directionsArray = directions.split('\n');

  return (
  <>
    <Typography variant="h6" fontWeight="bold" paragraph>
      Directions:
    </Typography>

    {directionsArray.map((direction: string) => (
      <Typography paragraph>
        {direction}
      </Typography>
    ))}
  </>
  )
}

function Calories({ calories }: interfaces.CaloriesProps) {

  return (
  <>
    <Typography variant="h6" fontWeight="bold" paragraph>
      Calories/Nutritional Info:
    </Typography>

      <Typography paragraph>
        {calories}
      </Typography>
  </>
  )
}

function removeFromUpcomingMeals(upcomingMeals: interfaces.Meal[], setUpcomingMeals: React.Dispatch<React.SetStateAction<Meal[]>>, meal: Meal) {
  const filteredMeals = upcomingMeals.filter((item) => item.recipe.recipeId !== meal.recipe.recipeId);
  setUpcomingMeals(filteredMeals);
  removeMealFromUpcomingMealsInDB(meal.recipe.recipeId);
}

async function removeMealFromUpcomingMealsInDB(id: string) {
  const removeFromUpcomingMealsReq: removeFromUpcomingMealsRequest = new removeFromUpcomingMealsRequest(id);

  await axios.post(`${process.env.REACT_APP_BACKEND_URL}/removefromupcomingmeals`, JSON.stringify(removeFromUpcomingMealsReq), {
    withCredentials: true,
    headers: {
      'Authorization': `Bearer ${getJWTToken()}`,
    }
  })
  .then(response => {
    // removed message
  })
  .catch(error => {
    const err: errorResponse = new errorResponse(error);
    // Display something 
  })
}

function mealNameToImage(mealName: string): string {
  const upperRange = 26;
  let total = 0;

  for (const char of mealName) {
    total = total + char.charCodeAt(0)
  }

  return `/mealIcon${((total % upperRange) + upperRange) % upperRange + 1}.jpg` ;
}
