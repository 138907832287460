import { jwtDecode } from "jwt-decode";

function getUsername() {
    return getClaimFromJWT('sub')
}

function getEmail() {
    return getClaimFromJWT('email')
}

function getJWTToken() {
    return getCookie('jwtToken');
}

export {
    getJWTToken,
    getUsername,
    getEmail
}

function getClaimFromJWT(claim: string) {
    const jwtToken = getJWTToken();
    if (jwtToken == null) {
        return null;
    } else {
        const decoded: DecodedToken = jwtDecode(jwtToken);
        return decoded[claim];
    }
}


function getCookie(cookieName: string) {
    const cookies = document.cookie.split(';')
    for (let i=0; i < cookies.length; i++) {
        if (cookies[i]) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1);
            }
        }
    }
    return null;
}

interface DecodedToken {
    iss: string;
    sub: string;
    exp: BigInteger;
    iat: BigInteger;
}

interface DecodedToken {
    [key: string]: any;
}
