import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  createTheme,
  ThemeProvider,
  CircularProgress
} from '@mui/material';
import './index.css'
import { getJWTToken } from './utils/utils'
import axios from 'axios';
import SignUpPopup from './reactComponents/signUpPopup';
import LoginPage from './reactComponents/loginPage';
import HomePage from './reactComponents/homePage';
import PlanMealsPage from './reactComponents/planMealsPage';
import Navbar from './reactComponents/navBar';
import AddRecipePage from './reactComponents/addRecipePage';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import errorResponse from './responses/errorResponse';
import GroceryListPage from './reactComponents/groceryListPage';
import RecipeBookPage from './reactComponents/recipeBookPage';
import SharePage from './reactComponents/shareMealsPage';
import { interfaces } from './interfaces';
import acceptSharedMealRequest from './requests/acceptSharedMealRequest';
import rejectSharedMealRequest from './requests/rejectSharedMealRequest';
import { AlertComponent } from './reactComponents/alertComponent';
import { AlertContext, alertMessages } from './index';

const appTheme = createTheme({
  palette: {
    primary: {
      main: '#cee3eb',
    },
    secondary: {
      main: '#aeaeae24',
    },
  },
});

const PlanPageContext = createContext<interfaces.PlanPageContext> (
  {
    ingredients: [],
    queuedMeal: null,
    setIngredients: () => {},
    onMinusClick: () => {},
    onPlusClick: () => {}
  }
);

const PlanPageProvider: React.FC<interfaces.PlanPageProviderProps> = ({ children }) => {

  const [queuedMeal, setQueuedMeal] = useState<interfaces.Meal|null>(null);
  const [ingredients, setIngredients] = useState<string[]>([]);

  const onMinusClick = (id: string) => {
    setQueuedMeal(null);
  };

  const onPlusClick = (mealToAdd: interfaces.Meal) => {
    setQueuedMeal(mealToAdd);
  }

  return (
      <PlanPageContext.Provider 
        value={
          { 
            onMinusClick,
            onPlusClick,
            queuedMeal,
            ingredients,
            setIngredients
          }}>
            {children}
      </PlanPageContext.Provider>
  )
};

const HomePageContext = createContext<interfaces.HomePageContext>(
  {
    upcomingMeals: [],
    setUpcomingMeals: () => {},
    acceptMeal: () => {},
    rejectMeal: () => {}
  }
);

const HomePageProvider: React.FC<interfaces.HomePageProviderProps> = ({ children }) => {

  const [upcomingMeals, setUpcomingMeals] = useState<interfaces.Meal[]>([]);

  const {
    timedAlert
  } = useContext(AlertContext)

  const acceptMeal = async(id: string) => {
    const acceptSharedMealReq: acceptSharedMealRequest = new acceptSharedMealRequest(id);
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/addsharedmeal`, JSON.stringify(acceptSharedMealReq), {
        withCredentials: true,
        headers: {
            'Authorization': `Bearer ${getJWTToken()}`,
        }
      }).then(response => {
        timedAlert(alertMessages.sharedMealAddedSuccessfully)
    })
    .catch(error => {
        const err: errorResponse = new errorResponse(error);
        // Display something

    })
  }

  const rejectMeal = async(id: string) => {
      const rejectSharedMealReq: rejectSharedMealRequest = new rejectSharedMealRequest(id);
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/rejectsharedmeal`, JSON.stringify(rejectSharedMealReq), {
          withCredentials: true,
          headers: {
              'Authorization': `Bearer ${getJWTToken()}`,
          }
      }).then(response => {
        timedAlert(alertMessages.sharedMealRejectedSuccessfully);
    })
    .catch(error => {
        const err: errorResponse = new errorResponse(error);
        // Display something
    })
  }

  return (
      <HomePageContext.Provider 
        value={
          { 
            upcomingMeals,
            setUpcomingMeals,
            acceptMeal,
            rejectMeal
          }}>
            {children}
      </HomePageContext.Provider>
  )
};


const SharePageContext = createContext<interfaces.SharePageContext>(
  {
    showSharePopup: false,
    setShowSharePopup: () => {},
    setMealToShare: () => {},
    mealToShare: ""
  }
);

const SharePageProvider: React.FC<interfaces.SharePageProviderProps> = ({ children }) => {

  const [showSharePopup, setShowSharePopup] = useState<boolean>(false);
  const [mealToShare, setMealToShare] = useState<string>('')

  return (
      <SharePageContext.Provider 
        value={{
          showSharePopup,
          setShowSharePopup,
          setMealToShare,
          mealToShare
        }}>
            {children}
      </SharePageContext.Provider>
  )
};

const PrivateRoute = ({ protectedRoute }: interfaces.PrivateRouteProps) => {
  const [isLoading, setLoading] = useState(true);
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkJWT = async () => {
      setJwtToken(getJWTToken());
      if (jwtToken) {
          await axios.get(`${process.env.REACT_APP_BACKEND_URL}/validateuser`, {
            withCredentials: true,
            headers: {
              'Authorization': `Bearer ${jwtToken}`,
            }
          })
          .then((response) => {
            // sets JwtToken
          })
          .catch((response) => {
            const errorRes: errorResponse = new errorResponse(response);
            if (errorRes.getStatus() == 401){
              setJwtToken(null);
            } else {
              setJwtToken(null);
            }
          })
          .finally(() => {
            setLoading(false);
          })
      } else {
        setLoading(false);
      }
    }

    checkJWT();
  }, []);

  if (isLoading) {
    return (
      <>
        <CircularProgress />
      </>
    );
  } else if (jwtToken != null) {
    return protectedRoute;
  } else {
    navigate('/');
    return null;
  }
};

function App() {
  const [isSignUpPopupOpen, setSignUpPopupOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const openPopup = () => {
    setSignUpPopupOpen(true);
  };

  const closePopup = () => {
    setSignUpPopupOpen(false);
  };

  const {
    currAlert
  } = useContext(AlertContext);

  useEffect(() => {
    const checkJWT = async () => {
      setLoading(true);
      const storedJWT = getJWTToken();
      if (storedJWT) {
        
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/validateuser`, {
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${storedJWT}`,
          }
        })
        .then(response => {
          // create a validate user response instead of this:
        })
        .catch(error => {
          const err: errorResponse = new errorResponse(error);
          // setActiveAlert('unknownError')
        })
        .finally(() => {
          setLoading(false);
        })
      } else {
        setLoading(false);
      }
    }

    checkJWT();
  }, []);

  if (isLoading) {
    return (
      <>
        <CircularProgress />
      </>
    )
  }

  return (
      <ThemeProvider theme={appTheme}>
        <Router>
          <Navbar />

          <Routes>
            <Route 
              path='*' 
              element={
                <>
                  {isSignUpPopupOpen && <SignUpPopup isOpen={isSignUpPopupOpen} onClose={closePopup} />}
                  <LoginPage openPopup={openPopup} />
                </>
              } 
            />

            <Route
              path='/home'
              element={
                <PrivateRoute 
                  protectedRoute={
                    <HomePageProvider>
                      <HomePage />
                    </HomePageProvider>
                  } 
                />
              }
            />

            <Route
              path='/addrecipe'
              element={
                <PrivateRoute 
                  protectedRoute={
                    <AddRecipePage 
                      recipeName=''
                      ingredients={[]}
                      directions=''
                    />
                  } 
                />
              }
            />

            <Route
              path='/plan'
              element={
                <PrivateRoute 
                  protectedRoute={
                    <PlanPageProvider>
                      <PlanMealsPage />
                    </PlanPageProvider>
                  }
                />
              }
            />

            <Route
              path='/grocerylist'
              element={
                <PrivateRoute 
                  protectedRoute={
                    <GroceryListPage />
                  } 
                />
              }
            />

            <Route
              path='/recipebook'
              element={
                <PrivateRoute 
                  protectedRoute={
                    <RecipeBookPage />
                  } 
                />
              }
            />

            <Route
              path='/share'
              element={
                <PrivateRoute 
                  protectedRoute={
                    <SharePageProvider>
                      <SharePage />
                    </SharePageProvider>
                  } 
                />
              }
            />
          </Routes>

          <AlertComponent
            alert={currAlert}
          />
        </Router>
      </ThemeProvider>
  );
}

export default App;
export { PlanPageContext, HomePageContext, SharePageContext }


function removeMealFromQueuedMeals(id: string, queuedMeals: interfaces.QueuedMeal[], allMeals: interfaces.Meal[], setAllMeals: React.Dispatch<React.SetStateAction<interfaces.Meal[]>>) {
  
  const updatedAllMeals = setDateBackToNull(id, allMeals);
  setAllMeals(updatedAllMeals)

  return queuedMeals.filter((queuedMeal) => queuedMeal.meal.recipe.recipeId !== id);
}

function addToQueuedMeals(queuedMeals: interfaces.QueuedMeal[], mealToAdd: interfaces.QueuedMeal) {
  queuedMeals.push(mealToAdd);
  return queuedMeals;
}

function setDateBackToNull(id: string, allMeals: interfaces.Meal[]) {
  for (let meal of allMeals) {
    if (meal.recipe.recipeId === id) {
      meal.date = null;
    }
  }

  return allMeals;
}