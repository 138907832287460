import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Container,
  Typography,
  Button,
  CircularProgress
} from '@mui/material';
import MealCard from './mealCard';
import { getJWTToken } from '../utils/utils';
import getAllMealsResponse from '../responses/getAllMealsResponse';
import errorResponse from '../responses/errorResponse';
import { interfaces } from '../interfaces';
import getUsersFiltersResponse from '../responses/getUsersFiltersResponse';


function PlanMealsPage() {
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
    const [allLabels, setAllLabels] = useState<string[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [allMeals, setAllMeals] = useState<interfaces.Meal[]>([]);

    const handleLabelCheckboxClicked = (selected: string[]) => {
        setSelectedLabels(selected);
    };

    const getUsersFilters = async () => {

        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getusersfilters`, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            }
        })
        .then(response => {
            const getUsersFiltersRes: getUsersFiltersResponse = new getUsersFiltersResponse(response)
            setAllLabels(getUsersFiltersRes.getUsersFilters())
        })
        .catch(error => {
            const err: errorResponse = new errorResponse(error);
            // Display something
        })
    }

    const getAllUsersMeals = async () => {

        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getallmeals`, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            }
        })
        .then(response => {
          const getAllMealsRes: getAllMealsResponse = new getAllMealsResponse(response);
          const allMeals: interfaces.Meal[] = getAllMealsRes.getAllRecipes();

          setAllMeals(allMeals);
        })
        .catch(error => {
            const err: errorResponse = new errorResponse(error);
            // Display something
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getAllUsersMeals();
            await getUsersFilters();
            setLoading(false);
        };

        fetchData();
    }, []);
  
    return (
        <>
            <Container style={{ display: 'flex', alignItems: 'center' }}>
                <CheckboxDropdown
                    options={allLabels}
                    selectedValues={selectedLabels}
                    onChange={handleLabelCheckboxClicked} // do I need all 4 of these?
                    setSelectedOptions={setSelectedLabels}
                />
            </Container>

            {
                !isLoading ? 
                (allMeals.length === 0 &&
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h5">No Meals to Plan :(</Typography>
                        <Button variant="contained" color="primary" href="/addrecipe" style={{height: '50px', marginTop: '30px'}}>Add Recipes Here!</Button>
                    </div>
                ) : (
                    <CircularProgress />
                )
            }

            {
                !isLoading ? 
                (allMeals.map((meal) => (
                    hasASelectedLabelOrNoLabelsSelected(meal, selectedLabels) && (
                        <MealCard 
                            key={`${meal.recipe.recipeId}-meal-item`}
                            meal={meal}
                            allowEdit={true}
                            allowQueueAndUnqueue={true}
                        />
                    )
                ))
                ) : (
                    <CircularProgress />
                )
            }
        </>
    );
}

function hasASelectedLabelOrNoLabelsSelected(meal: interfaces.Meal, selectedLabels: string[]) {
    if (selectedLabels.length == 0) {
        return true
    } else {
        return selectedLabels.every(label => meal.filters.includes(label)) ||
               selectedLabels.length > 0 && meal.filters.length === 0;
    }
}

function CheckboxDropdown({ options, selectedValues, setSelectedOptions, onChange }: interfaces.CheckboxDropdownProps) {
    const [open, setOpen] = useState(false);
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    return (
        <FormControl fullWidth style={{ marginTop: '80px', marginBottom: '20px' }}>
          <InputLabel>Select Labels</InputLabel>
          <Select
            multiple
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={selectedValues}
            onChange={(event) => {
              setSelectedOptions(event.target.value as string[]);
              onChange(event.target.value as string[]);
            }}
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            {options.map((option) => (
                <MenuItem key={`${option}-menu-item`} value={option}>
                    <Checkbox checked={selectedValues.includes(option)} />
                    <ListItemText primary={option} />
                </MenuItem>
            ))}
          </Select>
        </FormControl>
    );
}

export default PlanMealsPage;
