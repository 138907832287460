import loginUserRequest from '../requests/loginUserRequest';
import React, { useContext, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Typography,
  TextField,
  Box,
  CircularProgress,
  Card
} from '@mui/material';
import errorResponse from '../responses/errorResponse';
import { interfaces } from '../interfaces';
import { useNavigate } from 'react-router-dom';
import { AlertComponent } from './alertComponent';
import { AlertContext, alertMessages } from '..';
  
function LoginPage({ openPopup }: interfaces.LoginPageProps) {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    timedAlert
  } = useContext(AlertContext);

  const navigate = useNavigate();

  const handleSignIn = async () => {
    if (email && password) {
      setLoading(true);
      const loginUserReq: loginUserRequest = new loginUserRequest(email, password);

      console.log('backend URL:', process.env.REACT_APP_BACKEND_URL)

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/loginuser`, JSON.stringify(loginUserReq), {
        withCredentials: true,
      })
      .then(response => {
        navigate('/home')
      })
      .catch(error => {
        const err: errorResponse = new errorResponse(error);
        if (err.getStatus() == 401) {
          timedAlert(alertMessages.invalidCredentials)
        } else {
          timedAlert(alertMessages.unknownError)
        }
      }).finally(() => {
        setLoading(false);
      })
    } else {
      timedAlert(alertMessages.missingInformation)
    }
  }


  if (isLoading) {

    return (
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <CircularProgress />
      </Box>
    )
  } else {

    return (
      <Card variant="outlined" 
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
          padding: 2,
        }}
      >
        <Typography variant="h5">Please Login:</Typography>
    
        <TextField
          fullWidth
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
    
        <TextField
          fullWidth
          label="Password"
          placeholder="Password"
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
    
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={openPopup}>
            Sign-up
          </Button>
          <Button variant="outlined" color="primary" sx={{ ml: 2 }} onClick={handleSignIn}>
            Login
          </Button>
        </Box>
      </Card>
    );
  }
}

export default LoginPage;
