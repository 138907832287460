import React, { createContext, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { interfaces } from './interfaces';

const AlertContext = createContext<interfaces.AppContext>(
  {
    currAlert: null,
    timedAlert: (alert: interfaces.alert) => {}
  }
);

const AlertProvider: React.FC<interfaces.AlertProviderProps> = ({ children }) => {

  const [currAlert, setCurrAlert] = useState<interfaces.alert | null>(null);

  const timedAlert = (alert: interfaces.alert) => {
    setTimeout(() => {
      setCurrAlert(null);
    }, 5000);

    setCurrAlert(alert)
  }

  return (
      <AlertContext.Provider 
        value={
          { 
            currAlert,
            timedAlert
          }}>
            {children}
      </AlertContext.Provider>
  )
};



const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <AlertProvider>
      <App />
    </AlertProvider>
  </React.StrictMode>
);


export const alertMessages: Record<string, interfaces.alert> = {
  invalidCredentials: {
    type: 'error',
    title: 'Username or Password are incorrect',
    content: 'Please try signing in again.',
  },
  groceryItemsSavedSuccessfully: {
    type: 'success',
    title: 'Items Saved Successfully',
    content: 'Items were successfully saved to cart',
  },
  unknownError: {
    type: 'error',
    title: 'An Unknown Error Occurred',
    content: 'Please try again later or contact the developer for help.',
  },
  missingInformation: {
    type: 'error',
    title: 'Missing Information',
    content: 'Email and Password are both required.',
  },
  mealAddedToUpcomingMeals: {
    type: 'success',
    title: 'Saved Successfully',
    content: 'Your Meal was Added to Upcoming Meals.',
  },
  recipeDeletedSuccessfully: {
    type: 'success',
    title: 'Deleted Successfully',
    content: 'Your Meal was deleted from your recipe book.',
  },
  editsSavedSuccessfully: {
    type: 'success',
    title: 'Edits Saved Successfully',
    content: 'Your Edits were Successfully Saved.',
  },
  groceryCartUpdatedSuccessfully: {
    type: 'success',
    title: 'Items Removed',
    content: 'Items were successfully removed from your cart.',
  },
  mealAddedSuccessfully: {
    type: 'success',
    title: 'Meal Created',
    content: 'Meal was sucessfully created.',
  },
  mealSharedSuccessfully: {
    type: 'success',
    title: 'Shared Successfully',
    content: 'Meal was sucessfully shared.',
  },
  sharedMealAddedSuccessfully: {
    type: 'success',
    title: 'Added Successfully',
    content: 'Meal was sucessfully added.',
  },
  sharedMealRejectedSuccessfully: {
    type: 'success',
    title: 'Meal Rejected',
    content: 'Meal was NOT added.',
  },
  userSuccessfullyCreated: {
    type: 'success',
    title: 'User Created',
    content: 'User Successfully Created.',
  }
};


export { AlertContext }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
