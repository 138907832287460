import request from './request'

class addItemsToGroceryCartRequest extends request {
    groceryItems: string[];

    constructor(groceryItems: string[]) {
        super();
        this.groceryItems = groceryItems;
    }
}

export default addItemsToGroceryCartRequest;