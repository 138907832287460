import request from './request'
import { interfaces } from '../interfaces';

class saveUpcomingMealRequest extends request {

  private upcomingMeal: interfaces.idDate
  private ingredientsToAddToGroceryList: string[]
  
  constructor(plannedMeal: interfaces.Meal|null, ingredientsToAddToGroceryList: string[]) {
    super();
    if (plannedMeal?.recipe) {
      const idDate = {
        date: plannedMeal.date,
        recipeId: plannedMeal.recipe.recipeId
      }
      this.upcomingMeal = idDate;
    } else {
      this.upcomingMeal = {
        date: null,
        recipeId: ''
      };
    }

    this.ingredientsToAddToGroceryList = ingredientsToAddToGroceryList;
  }
}

export default saveUpcomingMealRequest
