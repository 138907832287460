import React, { useState } from 'react';
import { 
  Container,
  Dialog, 
  DialogTitle
} from '@mui/material';
import MealCard from './mealCard';
import { interfaces } from '../interfaces';
import TimedPopup from './timedPopup';

function SharedMealsPopup({
    isOpen,
    onClose,
    sharedMeals
}: interfaces.SharedMealsPopup) {

    const [showTemporarySuccessPopup, setShowTemporatySuccessPopup] = useState<boolean>(false);

    return (
    <Dialog open={isOpen} onClose={onClose} style={{ width: '100%' }}>
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DialogTitle>Shared Meals:</DialogTitle>
            {sharedMeals.map((recipe) => {
                const meal: interfaces.Meal = {
                    filters: [],
                    recipe: recipe,
                    added: false,
                    date: null
                }

                return (
                    <>
                        <MealCard 
                            key={meal.recipe.recipeId}
                            meal={meal}
                            allowEdit={false}
                            allowAcceptReject={true}
                        />

                    </>    
                )
            })}
        </Container>

        { showTemporarySuccessPopup && <TimedPopup /> }

    </Dialog>
  );
}

export default SharedMealsPopup;