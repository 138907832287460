import request from './request'

class createUserRequest extends request {
  private username: string;
  private email: string;
  private password: string;  
  
  constructor(username: string, email: string, password: string) {
    super();
    this.username = username;
    this.email = email;
    this.password = password;
  }
}

export default createUserRequest