import React, { useState } from 'react';
import { 
    Alert,
    AlertTitle
} from '@mui/material';

const AlertPopup = () => {

    console.log('render')
    
    return (
        <div style={{ position: 'fixed', zIndex: 9999, top: '90%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Your ingredients were saved
            </Alert>
        </div>
        );
};

export default AlertPopup;