import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Container,
  CircularProgress
} from '@mui/material';
import MealCard from './mealCard';
import { getJWTToken } from '../utils/utils';
import getAllMealsResponse from '../responses/getAllMealsResponse';
import errorResponse from '../responses/errorResponse';
import { interfaces } from '../interfaces';
import getUsersFiltersResponse from '../responses/getUsersFiltersResponse';
import ShareMealPopup from './shareMealPopup';

function SharePage() {
    const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
    const [allMeals, setAllMeals] = useState<interfaces.Meal[]>([]);
    const [filterOptions, setFilterOptions] = useState<string[]>([]);
    const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);
    const [showShareMealPopup, setShowSharedMealPopup] = useState<boolean>(false);
    const [mealToShare, setMealToShare] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    const handleCheckboxChange = (selected: string[]) => {
        setSelectedLabels(selected);
    };

    const getAllUsersMeals = async () => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getallmeals`, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            }
        })
        .then(response => {
          const getAllMealsRes: getAllMealsResponse = new getAllMealsResponse(response);
          const allMeals: interfaces.Meal[] = getAllMealsRes.getAllRecipes();
          setAllMeals(allMeals);
        })
        .catch(error => {
            const err: errorResponse = new errorResponse(error);
            // Display something
        })
    }

    const getUsersFilters = async () => {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getusersfilters`, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            }
        })
        .then(response => {
            const getUsersFiltersRes: getUsersFiltersResponse = new getUsersFiltersResponse(response)
            setFilterOptions(getUsersFiltersRes.getUsersFilters())
        })
        .catch(error => {
            const err: errorResponse = new errorResponse(error);
            // Display something
        })
    }


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getAllUsersMeals();
            await getUsersFilters();
            setLoading(false);
        };
      
        fetchData();
    }, []);

    const closeSharePopup = () => {
        setShowSharedMealPopup(false)
    }

    return (
        <>
            {
                !loading ? (
                    <>
                        <Container style={{ display: 'flex', alignItems: 'center' }}>
                            <CheckboxDropdown
                                options={filterOptions}
                                selectedValues={selectedLabels}
                                onChange={handleCheckboxChange}
                                setSelectedOptions={setSelectedLabels}
                            />
                        </Container>

                        { allMeals.map((meal) => (
                            hasASelectedLabelOrNoLabelsSelected(meal, selectedLabels) && (
                                <MealCard 
                                    key={meal.recipe.recipeId}
                                    meal={meal}
                                    allowEdit={false}
                                    allowShare={true}
                                />
                            )
                        ))}

                        { showShareMealPopup && 
                            <ShareMealPopup
                                isOpen={showShareMealPopup}
                                onClose={closeSharePopup}
                                mealId={mealToShare}
                            />
                        }
                    </>
                ) : (
                    <CircularProgress />
                )
            }
        </>
    );
}

function hasASelectedLabelOrNoLabelsSelected(meal: interfaces.Meal, selectedLabels: string[]) {
    if (selectedLabels.length == 0) {
        return true
    } else {
        return selectedLabels.every(label => meal.filters.includes(label)) ||
               selectedLabels.length > 0 && meal.filters.length === 0;
    }
}

function CheckboxDropdown({ options, selectedValues, setSelectedOptions, onChange }: interfaces.CheckboxDropdownProps) {
    const [open, setOpen] = useState(false);
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    return (
        <FormControl fullWidth style={{ marginTop: '80px', marginBottom: '20px' }}>
          <InputLabel>Select Labels</InputLabel>
          <Select
            multiple
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={selectedValues}
            onChange={(event) => {
              setSelectedOptions(event.target.value as string[]);
              onChange(event.target.value as string[]);
            }}
            renderValue={(selected) => (selected as string[]).join(', ')}
          >
            {options.map((option) => (
            <MenuItem key={option} value={option}>
                <Checkbox checked={selectedValues.includes(option)} />
                <ListItemText primary={option} />
            </MenuItem>
            ))}
          </Select>
        </FormControl>
    );
}

export default SharePage;
