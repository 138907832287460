import request from './request'

class shareMealRequest extends request {
    private mealId: string;
    private shareToEmail: string;

    constructor(mealId: string, shareToEmail: string) {
        super();
        this.mealId = mealId;
        this.shareToEmail = shareToEmail;
    }
}

export default shareMealRequest;