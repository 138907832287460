import React, { useState, useContext } from 'react';
import createUserRequest from '../requests/createUserRequest';
import axios from 'axios';
import { 
  Modal,
  Button,
  Typography,
  TextField,
  Box,
  AlertTitle,
  Alert, 
  Dialog
} from '@mui/material';
import errorResponse from '../responses/errorResponse';
import { interfaces } from '../interfaces';
import { AlertContext, alertMessages } from '..';
import { useNavigate } from 'react-router-dom';

function SignUpPopup({ isOpen, onClose }: interfaces.SignUpPopupProps) {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [activeAlert, setActiveAlert] = useState<string | null>(null);

  const {
    timedAlert
  } = useContext(AlertContext);

  const navigate = useNavigate();

  const handleSignUp = async () => {
    if (name && email && password) {
      const createUserReq: createUserRequest = new createUserRequest(name, email, password);

      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/createuser`, JSON.stringify(createUserReq), {
        withCredentials: true,
      })
      .then(response => {
        timedAlert(alertMessages.userSuccessfullyCreated)
        navigate('/home')
      })
      .catch(error => {
        const err: errorResponse = new errorResponse(error)
        if (err.getStatus() == 409) {
          setActiveAlert('alreadyUsed')
        } else {
          setActiveAlert('unknownError')
        }
      })
    } else {
      setActiveAlert('notAllInformation')
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box 
        sx={{
          p: 3,
        }}
      >
        <Typography variant="h6">Thanks for Creating an Account!</Typography>
        <Typography variant="subtitle1">Please Enter the Following:</Typography>
  
        <TextField
          fullWidth
          label="Name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
  
        <TextField
          fullWidth
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
  
        <TextField
          fullWidth
          label="Password"
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />

        {activeAlert === 'alreadyUsed' && (
          <Alert severity="error">
            <AlertTitle>Cannot Create Account</AlertTitle>
            An account is already associated with this email.
          </Alert>
        )}

        {activeAlert === 'unknownError' && (
          <Alert severity="error">
            <AlertTitle>An Unknown Error Occurred</AlertTitle>
            Please try again later or contact the developer for help.
          </Alert>
        )}

        {activeAlert === 'notAllInformation' && (
          <Alert severity="error">
            <AlertTitle>Missing Information</AlertTitle>
            Name, Email, and Password are all required.
            Please provide all information
          </Alert>
        )}
  
      <Box mt={2} display="flex" justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={handleSignUp}>
          Sign-up
        </Button>
        <Button variant="outlined" color="primary" onClick={onClose} sx={{ ml: 2 }}>
          Close
        </Button>
      </Box>
    </Box>
  </Dialog>
  );
}

export default SignUpPopup;