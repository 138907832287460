import request from './request'

class removeFromGroceryListRequest extends request {
  private ingredientsToRemove: string[];
  
  constructor(ingredientsToRemove: string[]) {
      super();
      this.ingredientsToRemove = ingredientsToRemove;
    }
}

export default removeFromGroceryListRequest;