import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { 
  Button, 
  CircularProgress, 
  Container, 
  Grid,
  Typography
} from '@mui/material';
import MealCard from './mealCard';
import { getJWTToken } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import errorResponse from '../responses/errorResponse';
import getUpcomingMealsResponse from '../responses/getUpcomingMealsResponse';
import { interfaces } from '../interfaces';
import removeFromUpcomingMealsRequest from '../requests/removeFromUpcomingMealsRequest';
import getSharedMealsResponse from '../responses/getSharedMealsResponse';
import SharedMealsPopup from './sharedMealsPopup';
import { HomePageContext } from '../App'
  
export default function HomePage() {
  const [sharedMeals, setSharedMeals] = useState<interfaces.recipe[] | null>(null);
  const [isSharedMealsPopupOpen, setIsSharedMealsPopupOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    upcomingMeals,
    setUpcomingMeals
  } = useContext(HomePageContext);

  const navigate = useNavigate();

  const toAddRecipePage = () => {
    navigate('/addrecipe');
  }

  const toPlanMealsPage = () => {
    navigate('/plan')
  }

  const toSeeGroceryListPage = () => {
    navigate('/grocerylist')
  }

  const toRecipeBookPage = () => {
    navigate('/recipebook')
  }

  const toSharePage = () => {
    navigate('/share')
  }


  const getSharedMeals = async () => {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getsharedmeals`, {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${getJWTToken()}`,
      }
    })
    .then(response => {
      const getSharedMealsRes: getSharedMealsResponse = new getSharedMealsResponse(response);
      let sharedMeals = getSharedMealsRes.getSharedMeals();
      setSharedMeals(sharedMeals);
      if (sharedMeals.length > 0) {
        setIsSharedMealsPopupOpen(true);
      }
    })
    .catch(error => {
      const err: errorResponse = new errorResponse(error);
      // Display something
    })
  }

  const getUpcomingMeals = async () => {
    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getupcomingmeals`, {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${getJWTToken()}`,
      }
    })
    .then(response => {
      const getUpcomingMealsRes: getUpcomingMealsResponse = new getUpcomingMealsResponse(response);
      let upcomMeals = getUpcomingMealsRes.getUpcomingMeals();
      setUpcomingMeals(upcomMeals);
    })
    .catch(error => {
      const err: errorResponse = new errorResponse(error);
      // Display something
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getSharedMeals();
      await getUpcomingMeals();
      setLoading(false);
    };

    fetchData();
  }, []);

  const closeSharedMealsPopup = () => {
    setIsSharedMealsPopupOpen(false);
  }

  return (
    <>
      {
        !loading ? (
          <>
            <Container style={{ textAlign: 'center', marginTop: '80px' }}>
              <Typography variant="h5">What Would You Like to do?</Typography>

              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} md={4}>
                  <Button fullWidth variant="contained" color="primary" style={{ marginTop: '16px', height: '64px' }} onClick={toPlanMealsPage}>
                    Plan My Meals!
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button fullWidth variant="contained" color="primary" style={{ marginTop: '16px', height: '64px' }} onClick={toSeeGroceryListPage}>
                    See Grocery List
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button fullWidth variant="contained" color="primary" style={{ marginTop: '16px', height: '64px' }} onClick={toAddRecipePage}>
                    Add A Recipe
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button fullWidth variant="contained" color="primary" style={{ marginTop: '16px', height: '64px' }} onClick={toSharePage}>
                    Share Meals
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button fullWidth variant="contained" color="primary" style={{ marginTop: '16px', height: '64px' }} onClick={toRecipeBookPage}>
                    Recipe Book
                  </Button>
                </Grid>
              </Grid>
            </Container>

            { sharedMeals && 
              <SharedMealsPopup
                isOpen={isSharedMealsPopupOpen}
                onClose={closeSharedMealsPopup}
                sharedMeals={sharedMeals}
              /> 
            }

            <Container style={{ marginTop: '20px', textAlign: 'center' }}>
              { upcomingMeals.length > 0 && <Typography variant="h5">Upcoming Meals:</Typography> }
                {upcomingMeals.map((meal) => (
                  <MealCard 
                    meal={meal}
                    allowEdit={true}
                    allowRemoveFromUpcomingMeals={true}
                  />
                ))
                }

              { upcomingMeals.length == 0 && <Typography variant="h5">No Upcoming Meals!</Typography> }
            </Container>
          </>
        ) : (
          <CircularProgress />
        )
      }
    </>
  );
}
