import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Button,
  Container,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import errorResponse from '../responses/errorResponse';
import { getJWTToken } from '../utils/utils';
import getUsersGroceryListResponse from '../responses/getUsersGroceryListResponse';
import removeFromGroceryListRequest from '../requests/removeFromGroceryListRequest';
import removeFromGroceryListResponse from '../responses/removeFromGroceryListResponse';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AlertContext, alertMessages } from '..';
import AddGroceryItemPopup from '../reactComponents/groceryItemPopup';

export default function GroceryListPage() {

    const [allIngredients, setAllIngredients] = useState<string[]>([]);
    const [checkedIngredients, setCheckedIngredients] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showAddGroceryItemPopup, setAddGroceryItemPopup] = useState<boolean>(false);

    const {
        timedAlert
    } = useContext(AlertContext);

    const theme = useTheme();

    const handleCheckBoxClicked = (ingredient: string) => {
        setCheckedIngredients((previousCheckedIngredients) =>
        previousCheckedIngredients.includes(ingredient)
            ? previousCheckedIngredients.filter((selectedIngredient) => selectedIngredient !== ingredient)
            : [...previousCheckedIngredients, ingredient]
        );
    };


    const getUsersGroceryList = async () => {

        setLoading(true);
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getgrocerylist`, {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            }
        })
        .then(response => {
            const getUsersGroceryListRes: getUsersGroceryListResponse = new getUsersGroceryListResponse(response);
            setAllIngredients(getUsersGroceryListRes.getGroceryList() ? getUsersGroceryListRes.getGroceryList() : []);
        })
        .catch(error => {
            const err: errorResponse = new errorResponse(error);
            setLoading(false);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const handleButtonClick = async() => {

        setLoading(true);

        const removeFromGroceryListReq: removeFromGroceryListRequest = new removeFromGroceryListRequest(checkedIngredients);

        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/removefromgrocerylist`, JSON.stringify(removeFromGroceryListReq), {
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${getJWTToken()}`,
            }
        })
        .then(response => {
            const removeFromGroceryListRes = new removeFromGroceryListResponse(response);
            setAllIngredients(removeFromGroceryListRes.getUpdatedGroceryList())
            setCheckedIngredients([]);
            timedAlert(alertMessages.groceryCartUpdatedSuccessfully);
            getUsersGroceryList();
        })
        .catch(error => {
            const err: errorResponse = new errorResponse(error);
            // Display something
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getUsersGroceryList();
            setLoading(false);
        };

        fetchData();
    }, []);

    const addItemOnClick = () => {
        setAddGroceryItemPopup(true)
    }

    return (
        <>
            <Container style={{ textAlign: 'center', marginTop: '80px' }} maxWidth="md">
                <Typography variant="h5" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    Grocery List:
                    <AddCircleIcon onClick={addItemOnClick} style={{ marginLeft: '10px', color: theme.palette.primary.main }} fontSize="large" />
                </Typography>
            </Container>

            <AddGroceryItemPopup
                isOpen={showAddGroceryItemPopup}
                onClose={() => {
                    setAddGroceryItemPopup(false);
                    getUsersGroceryList();
                }}
            />

            <Container>
                { !loading ? 
                    (
                        <FormGroup style={{ marginLeft: '40px' }}>
                            {allIngredients.length > 0 ? (
                                allIngredients.map((ingredient: string) => (
                                    <FormControlLabel 
                                        key={ingredient} 
                                        control={<Checkbox onChange={() => handleCheckBoxClicked?.(ingredient)} />} 
                                        label={ingredient}
                                    />
                                ))
                            ) : (
                                <Typography variant="h6">Nothing in Cart!</Typography>
                            )}
                        </FormGroup>
                    ) : (
                        <CircularProgress />
                    )
                }

                <Container style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                    <Button variant="contained" onClick={handleButtonClick} >
                        Save Changes
                    </Button>
                </Container>
            </Container>
        </>
    )
}
