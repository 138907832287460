import React from 'react';
import {
  AlertTitle,
  Alert
} from '@mui/material';
import { interfaces } from '../interfaces';

export const AlertComponent = ({ alert }: interfaces.AlertComponentProps) => {
    if (alert == null) {
      return <></>
    } 

    return (
      <div style={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', width: '80%', zIndex: 9999 }}>
        <Alert severity={alert.type}>
          <AlertTitle>{alert.title}</AlertTitle>
          {alert.content}
        </Alert>
      </div>
    )
}
  