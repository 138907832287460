import request from './request'

class rejectSharedMealRequest extends request {
    recipeId: string;

    constructor(recipeId: string) {
        super();
        this.recipeId = recipeId;
    }
}

export default rejectSharedMealRequest;