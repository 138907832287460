import React, { useContext } from 'react';
import {
  FormGroup,
  Button,
  Dialog,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { interfaces } from '../interfaces';
import { PlanPageContext } from '../App';

export default function AddToGroceryCartPopup({
    isOpen,
    onClose,
    meal
}: interfaces.AddToGroceryCartPopupProps) {

    let { 
        ingredients,
        setIngredients
    } = useContext(PlanPageContext);

    return (
        <>
            <Dialog 
                open={isOpen} 
                onClose={onClose}
                fullWidth
                maxWidth="md"
            >
                <Container style={{ textAlign: 'center', marginTop: '20px' }} maxWidth="md">
                    <Typography variant="h5" style={{ marginBottom: '20px' }}>Select Ingredients:</Typography>

                    <FormGroup style={{ marginLeft: '20px' }}>
                        {meal.recipe.ingredients.map((ingredient: string) => (
                            <FormControlLabel 
                                key={ingredient} 
                                control={<Checkbox onChange={() => handleIngredientCheckBoxClicked(ingredients, ingredient, setIngredients)} />} 
                                label={ingredient}
                            />
                        ))}
                    </FormGroup>
                </Container>

                <Container style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                    <Button variant="contained" onClick={onClose}>
                        Save and Close
                    </Button>
                </Container>
            </Dialog>
        </>
    )
}

function handleIngredientCheckBoxClicked(ingredients: string[], ingredient: string, setIngredients: React.Dispatch<React.SetStateAction<string[]>>) {
    if (ingredientIsAlreadyAdded(ingredients, ingredient)) {
        ingredients.filter(addedingredient => addedingredient !== ingredient)
    } else {
        ingredients.push(ingredient);
    }

    setIngredients(ingredients);
}

function ingredientIsAlreadyAdded(ingredients: string[], ingredient: string) {
    if (ingredients.includes(ingredient)) {
        return true;
    } else {
        return false;
    }
}
