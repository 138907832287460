import request from './request'

class deleteRecipeRequest extends request {
  private idToDelete: string;
  private username: string;
  
  constructor(idToDelete: string, username: string) {
    super();
    this.idToDelete = idToDelete;
    this.username = username;
  }
}

export default deleteRecipeRequest;