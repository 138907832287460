import React, { useContext, useState } from 'react';
import axios from 'axios';
import { 
  Container,
  Dialog, 
  DialogTitle,
  Button,
  Paper,
  TextField
} from '@mui/material';
import { getJWTToken } from '../utils/utils';
import errorResponse from '../responses/errorResponse';
import { interfaces } from '../interfaces';
import shareMealRequest from '../requests/shareMealRequest';
import { AlertContext, alertMessages } from '..';

function ShareMealPopup({ 
    isOpen,
    onClose,
    mealId
  }: interfaces.ShareMealsPopupProps) {

  const {
    timedAlert
  } = useContext(AlertContext)
    
  const [shareToEmail, setShareToEmail] = useState<string>('');
  const [activeAlert, setActiveAlert] = useState<string | null>('');

  const shareMeal = async() => {
    const shareMealReq: shareMealRequest = new shareMealRequest(mealId, shareToEmail);
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sharemeal`, JSON.stringify(shareMealReq), {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${getJWTToken()}`,
        }
    }).then(response => {
      timedAlert(alertMessages.mealSharedSuccessfully)
      onClose()
    })
    .catch(error => {
        const err: errorResponse = new errorResponse(error);
        // Display something
    })
  };

  return (
    <Dialog open={isOpen} onClose={onClose} style={{ width: '100%' }}>
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DialogTitle>Enter the email of the user you would like to share with:</DialogTitle>
            <TextField 
              id="outlined-basic" 
              label="Email" 
              variant="outlined"
              value={shareToEmail}
              onChange={(e) => setShareToEmail(e.target.value)}
            />
            <Button variant="contained" style={{ marginBottom: '10px', marginTop: '20px' }} onClick={shareMeal}>Share</Button>
        </Container>
    </Dialog>
  );
}

export default ShareMealPopup;
