import React, { useState } from 'react';
import { AppBar, Menu, Toolbar, MenuItem, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const navigateTo = (url: string) => {
    window.location.href = url;
    handleClose()
  }

  const handleOnHomeClick = () => {
    navigate('/home');
  }

  return (
    <AppBar position="fixed" style={{ top: 0 }}>
      <Toolbar>
        <div onClick={handleClick} style={{ paddingRight: '14px' }}>
          <MenuIcon />
        </div>
        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigateTo('https://github.com/tchild1')}>See Github</MenuItem>
          <MenuItem onClick={() => navigateTo('https://tannerchild.com')}>Learn More</MenuItem>
          <MenuItem onClick={handleClose}>Contribute</MenuItem>
        </Menu>

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          PlanMyMeals
        </Typography>

        <Button color="inherit" onClick={handleOnHomeClick}>Home</Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
