import request from './request'

class editRecipeRequest extends request {

    private recipeName: string;
    private recipeIngredients: string[];
    private recipeDirections: string;
    private recipeSelectedFilters: string[];
    private recipeId: string;
    private recipeCalories: string;
  
  constructor(recipeName: string, recipeIngredients: string[], recipeDirections: string, recipeSelectedFilters: string[], recipeId: string, recipeCalories: string) {
    super();
    this.recipeName = recipeName;
    this.recipeIngredients = recipeIngredients;
    this.recipeDirections = recipeDirections;
    this.recipeSelectedFilters = recipeSelectedFilters;
    this.recipeId = recipeId;
    this.recipeCalories = recipeCalories;
  }
}

export default editRecipeRequest