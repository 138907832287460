import response from './response'
import { AxiosResponse } from 'axios';
import { interfaces } from '../interfaces';

class getUsersGroceryListResponse extends response {

    private groceryList: Array<string>

    constructor(response: AxiosResponse) {
        super(response.status);

        this.groceryList = response.data.groceryList;
    }

    getGroceryList() {
        return this.groceryList;
    }
}

export default getUsersGroceryListResponse;
