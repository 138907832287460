import response from './response'
import { AxiosResponse } from 'axios';
import { interfaces } from '../interfaces';

class getUpcomingMealsResponse extends response {

    private upcomingMeals: Array<interfaces.Meal>;

    constructor(response: AxiosResponse) {
        super(response.status);
        this.upcomingMeals = response.data.upcomingMeals;
    }

    getUpcomingMeals() {
        return this.upcomingMeals
    }
}

export default getUpcomingMealsResponse;
