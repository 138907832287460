import response from './response'
import { AxiosResponse } from 'axios';

class removeFromGroceryListResponse extends response {

    private updatedGroceryList: Array<string>

    constructor(response: AxiosResponse) {
        super(response.status);

        this.updatedGroceryList = response.data.updatedGroceryList;
    }

    getUpdatedGroceryList() {
        return this.updatedGroceryList;
    }
}

export default removeFromGroceryListResponse;
