import request from './request'

class acceptSharedMealRequest extends request {
    recipeId: string;

    constructor(recipeId: string) {
        super();
        this.recipeId = recipeId;
    }
}

export default acceptSharedMealRequest;