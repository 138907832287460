import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Grid,
  Button,
  MenuItem,
  Container,
  Typography,
  TextField,
  Alert,
  AlertTitle,
  CircularProgress
} from '@mui/material';
import { interfaces } from '../interfaces';
import { getJWTToken } from '../utils/utils';
import getUsersFiltersResponse from '../responses/getUsersFiltersResponse';
import editRecipeRequest from '../requests/editRecipeRequest';
import { AlertContext, alertMessages } from '..';

export default function EditRecipePage({
    meal,
    onClose
  }: interfaces.EditRecipePageProps) {
    // Alert States
    const [recipeAddedSuccessAlert, setRecipeAddedSuccessAlert] = useState<boolean>(false);
    const [noNameAlert, setNoNameAlert] = useState<boolean>(false);
    const [noEmailAlert, setNoEmailAlert] = useState<boolean>(false);
    const [unknownErrorAlert, setUnknownErrorAlert] = useState<boolean>(false);

    const [newFilter, setNewFilter] = useState<string>('');
    const [allUserFilters, setAllUserFilters] = useState<string[]>([]);
    const [recipeNameValue, setRecipeNameValue] = useState<string>(meal.recipe.recipeName);
    const [recipeIngredientsValue, setRecipeIngredientsValue] = useState<string[]>(meal.recipe.ingredients);
    const [recipeDirectionsValue, setRecipeDirectionsValue] = useState<string>(meal.recipe.directions);
    const [recipeSelectedFilters, setRecipeSelectedFilters] = useState<string[]>(meal.filters);
    const [loading, setLoading] = useState<boolean>(true);
    const [recipeCaloriesValue, setRecipeCaloriesValue] = useState<string>(meal.recipe.calories);

    const {
      timedAlert
    } = useContext(AlertContext)

    const getUsersFilters = async () => {

      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getusersfilters`, {
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${getJWTToken()}`,
          }
      })
      .then(response => {
          const getUsersFiltersRes: getUsersFiltersResponse = new getUsersFiltersResponse(response)
          setAllUserFilters(getUsersFiltersRes.getUsersFilters())
      })
      .catch(error => {
          // const err: errorResponse = new errorResponse(error);
          // Display something
      })
    }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getUsersFilters();
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSaveButtonClick = async () => {
    await saveEdits(
      recipeNameValue,
      recipeIngredientsValue,
      recipeDirectionsValue,
      recipeSelectedFilters,
      meal.recipe.recipeId,
      recipeCaloriesValue
    )
  }

  const saveEdits = async (
      recipeName: string,
      recipeIngredients: string[],
      recipeDirections: string,
      recipeSelectedFilters: string[],
      editRecipeId: string,
      recipeCaloriesValue: string
    ) => {
    
    const editRecipeReq: editRecipeRequest = new editRecipeRequest(recipeName, recipeIngredients, recipeDirections, recipeSelectedFilters, editRecipeId, recipeCaloriesValue);
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}/editrecipe`, JSON.stringify(editRecipeReq), {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${getJWTToken()}`,
        }
    })
    .then(response => {
        // const editRecipeRes: editRecipeResponse = new editRecipeResponse(response)
        timedAlert(alertMessages.editsSavedSuccessfully)
        if (onClose) {
          onClose()
        }
    })
    .catch(error => {
        // const err: errorResponse = new errorResponse(error);
        // Display something
    })
  }

    const addNewFilter = () => {
      if (newFilter != '') {

        const filterAlreadyExists = (newOption: string) => {
          return allUserFilters.includes(newOption);
        }

        const filterAlreadySelected = (newOption: string) => {
          return recipeSelectedFilters.includes(newOption);
        }


        if (!filterAlreadyExists(newFilter) && !filterAlreadySelected(newFilter)) {
          setAllUserFilters((prevFilters) => [...prevFilters, newFilter]);  
        }

        if (!filterAlreadySelected(newFilter)) {
          setRecipeSelectedFilters((prevValues) => [...prevValues, newFilter]);
        }

        setNewFilter('');
      }
    };

    return (
        <>
          { !loading ? (
          <>
            <Container style={{ textAlign: 'center', marginTop: '80px' }} maxWidth="md">
              <Typography variant="h5" style={{ marginBottom: '20px' }}>Edit Recipe:</Typography>
      
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-helperText"
                    label="Recipe Name"
                    value={recipeNameValue}
                    onChange={(e) => setRecipeNameValue(e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline"
                    label="Ingredients"
                    value={recipeIngredientsValue.join('\n')}
                    onChange={(e) => setRecipeIngredientsValue(e.target.value.split('\n'))}
                    multiline
                    rows={4}
                    helperText="*Enter ONE Ingredient per Line*"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Directions"
                    value={recipeDirectionsValue}
                    onChange={(e) => setRecipeDirectionsValue(e.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Calories/Nutritional Facts"
                      value={recipeCaloriesValue}
                      onChange={(e) => setRecipeCaloriesValue(e.target.value)}
                      multiline
                      rows={2.5}
                      fullWidth
                    />
                  </Grid>
              </Grid>
            </Container>

            <Container>
              <Typography variant="h6" style={{ display: 'flex', marginTop: '10px' }}>
                Filters:
              </Typography>
              <Container style={{ display: 'flex' }}>
                <Container>
                  <TextField
                    select
                    label="Select Filters"
                    defaultValue={recipeSelectedFilters}
                    value={recipeSelectedFilters}
                    onChange={(e) => setRecipeSelectedFilters([...e.target.value])}
                    helperText="Please select this recipe's filters"
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => {
                          if (Array.isArray(selected)) {
                            return selected.map((item, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && <br />} {item}
                              </React.Fragment>
                            ));
                          }
                          return '';
                        },
                    }}
                  >
                    {allUserFilters.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                    ))}
                  </TextField>
                </Container>

                <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <TextField
                    label="Add New Filter"
                    value={newFilter}
                    onChange={(e) => setNewFilter(e.target.value)}
                    variant="outlined"
                  />
                    <Button onClick={addNewFilter} variant="contained" style={{ marginTop:'5px' }}>
                      Add New Filter
                    </Button>
                </Container>
              </Container>

              <Container
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '20px'
                }}
              >
                {recipeAddedSuccessAlert && (
                  <Alert severity="success">
                    <AlertTitle>
                      Meal Added Successfully
                    </AlertTitle>
                      Add another Meal!
                  </Alert>
                )}

                {noNameAlert && (
                  <Alert severity="error">
                    <AlertTitle>
                      Meal is Missing a Name!
                    </AlertTitle>
                      Please add a name before submitting your recipe. 
                  </Alert>
                )}

                {noEmailAlert && (
                  <Alert severity="error">
                    <AlertTitle>
                      An Error Occurred...
                    </AlertTitle>
                      No email found in JWT Token
                  </Alert>
                )}

                {unknownErrorAlert && (
                  <Alert severity="error">
                    <AlertTitle>
                      Unknown Error Occurred
                    </AlertTitle>
                      An Unknown Error Occurred. 
                  </Alert>
                )}
                <Button variant="contained" onClick={handleSaveButtonClick}>
                  Save Changes
                </Button>
              </Container>
            </Container>
          </>
        ) : (
          <CircularProgress/>
        )
      }
    </>
  )
}
