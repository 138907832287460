import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
  Grid,
  Button,
  MenuItem,
  Container,
  Typography,
  TextField,
  Alert,
  AlertTitle,
  CircularProgress
} from '@mui/material';
import createMealRequest from '../requests/createMealRequest';
import { getEmail } from '../utils/utils';
import errorResponse from '../responses/errorResponse';
import { interfaces } from '../interfaces';
import { getJWTToken } from '../utils/utils';
import getUsersFiltersResponse from '../responses/getUsersFiltersResponse';
import { AlertContext, alertMessages } from '..';

export default function AddRecipePage({
    recipeName,
    ingredients,
    directions
  }: interfaces.AddRecipePageProps) {
    // Alert States
    const [recipeAddedSuccessAlert, setRecipeAddedSuccessAlert] = useState<boolean>(false);
    const [noNameAlert, setNoNameAlert] = useState<boolean>(false);
    const [noEmailAlert, setNoEmailAlert] = useState<boolean>(false);
    const [unknownErrorAlert, setUnknownErrorAlert] = useState<boolean>(false);


    const [newFilter, setNewFilter] = useState<string>('');
    const [allUserFilters, setAllUserFilters] = useState<string[]>([]);
    const [recipeNameValue, setRecipeNameValue] = useState<string>(recipeName);
    const [recipeIngredientsValue, setRecipeIngredientsValue] = useState<string[]>(ingredients);
    const [recipeDirectionsValue, setRecipeDirectionsValue] = useState<string>(directions);
    const [recipeSelectedFilters, setRecipeSelectedFilters] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [recipeCaloriesValue, setRecipeCaloriesValue] = useState<string>('');

    const {
      timedAlert
    } = useContext(AlertContext);

    const getUsersFilters = async () => {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/getusersfilters`, {
        withCredentials: true,
        headers: {
          'Authorization': `Bearer ${getJWTToken()}`,
        }
      })
      .then(response => {
        const getUsersFiltersRes: getUsersFiltersResponse = new getUsersFiltersResponse(response)
        setAllUserFilters(getUsersFiltersRes.getUsersFilters())
      })
      .catch(error => {
        const err: errorResponse = new errorResponse(error);
        // Display something
      })
    }

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        await getUsersFilters();
        setLoading(false);
      };

      fetchData();
    }, []);

  const handleSaveButtonClick = async () => {
    await saveRecipe(
      recipeNameValue,
      recipeIngredientsValue,
      recipeDirectionsValue,
      recipeSelectedFilters,
      recipeCaloriesValue
    )
  }

  const saveRecipe = async (
      nameValue: string,
      ingredientsValue: string[],
      directionsValue: string,
      selectedFilters: string[],
      recipeCaloriesValue: string
    ) => {
    const email: string | null = getEmail();
    if (nameValue == '') {
      setNoNameAlert(true);
    } else {
      if (email == null) {
        setNoEmailAlert(true);
      } else {
        const createMealReq: createMealRequest = new createMealRequest(email, nameValue, ingredientsValue, directionsValue, selectedFilters.flat(2), recipeCaloriesValue);

        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/createmeal`, JSON.stringify(createMealReq), {
          withCredentials: true,
        })
        .then(response => {
          setRecipeNameValue('');
          setRecipeIngredientsValue([]);
          setRecipeDirectionsValue('');
          setRecipeSelectedFilters([]);
          setRecipeCaloriesValue('');
          timedAlert(alertMessages.mealAddedSuccessfully);
        })
        .catch(error => {
          const err: errorResponse = new errorResponse(error);
          // try to dynamically take the error message out of the errorRespone and display that for the user.
          setUnknownErrorAlert(true);
        })
      }
    }
  }

    const addNewFilter = () => {
      console.log(newFilter)
      if (newFilter != '') {

        const filterAlreadyExists = (newOption: string) => {
          return allUserFilters.includes(newOption);
        }

        const filterAlreadySelected = (newOption: string) => {
          return recipeSelectedFilters.includes(newOption);
        }

        if (!filterAlreadyExists(newFilter) && !filterAlreadySelected(newFilter)) {
          setAllUserFilters((prevFilters) => [...prevFilters, newFilter]);  
        }

        if (!filterAlreadySelected(newFilter)) {
          recipeSelectedFilters.push(newFilter)
          setRecipeSelectedFilters(recipeSelectedFilters);
        }

        setNewFilter('');
      }
      console.log(recipeSelectedFilters)
    };

    return (
      <>
        { !loading ? 
          (
            <>
              <Container style={{ textAlign: 'center', marginTop: '80px' }} maxWidth="md">
                <Typography variant="h5" style={{ marginBottom: '20px' }}>Add Recipe:</Typography>
  
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-helperText"
                      label="Recipe Name"
                      value={recipeNameValue}
                      onChange={(e) => setRecipeNameValue(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline"
                      label="Ingredients"
                      value={recipeIngredientsValue.join('\n')}
                      onChange={(e) => setRecipeIngredientsValue(e.target.value.split('\n'))}
                      multiline
                      rows={4}
                      helperText="*Enter ONE Ingredient per Line*"
                      fullWidth
                      InputProps={{
                        multiline: true,
                        rows: 4,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Directions"
                      value={recipeDirectionsValue}
                      onChange={(e) => setRecipeDirectionsValue(e.target.value)}
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Calories/Nutritional Facts"
                      value={recipeCaloriesValue}
                      onChange={(e) => setRecipeCaloriesValue(e.target.value)}
                      multiline
                      rows={2.5}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Container>

              <Container>
                <Typography variant="h6" style={{ display: 'flex', marginTop: '10px' }}>
                  Labels:
                </Typography>
                <Container style={{ display: 'flex' }}>
                  <Container>
                    <TextField
                      select
                      label="Select Labels"
                      defaultValue={[]}
                      value={recipeSelectedFilters}
                      onChange={(e) => setRecipeSelectedFilters([...e.target.value])}
                      helperText="Please select this recipe's labels"
                      SelectProps={{
                        multiple: true,
                        renderValue: (selected) => {
                          if (Array.isArray(selected)) {
                            return selected.map((item, index) => (
                              <React.Fragment key={index}>
                                {index > 0 && <br />} {item}
                              </React.Fragment>
                            ));
                          }
                          return '';
                        },
                      }}
                      >
                      {allUserFilters.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                      ))}
                    </TextField>
                  </Container>

                  <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                      label="Add New Label"
                      value={newFilter}
                      onChange={(e) => setNewFilter(e.target.value)}
                      variant="outlined"
                    />
                      <Button onClick={addNewFilter} variant="contained" style={{ marginTop:'5px' }}>
                        Add New Label
                      </Button>
                  </Container>
                </Container>

                <Container
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px'
                  }}
                >
                  {recipeAddedSuccessAlert && (
                    <Alert severity="success">
                      <AlertTitle>
                        Meal Added Successfully
                      </AlertTitle>
                        Add another Meal!
                    </Alert>
                  )}

                  {noNameAlert && (
                    <Alert severity="error">
                      <AlertTitle>
                        Meal is Missing a Name!
                      </AlertTitle>
                        Please add a name before submitting your recipe. 
                    </Alert>
                  )}

                  {noEmailAlert && (
                    <Alert severity="error">
                      <AlertTitle>
                        An Error Occurred...
                      </AlertTitle>
                        No email found in JWT Token
                    </Alert>
                  )}

                  {unknownErrorAlert && (
                    <Alert severity="error">
                      <AlertTitle>
                        Unknown Error Occurred
                      </AlertTitle>
                        An Unknown Error Occurred. 
                    </Alert>
                  )}

                  <Button variant="contained" onClick={handleSaveButtonClick}>
                    Save Recipe
                  </Button>
                </Container>
              </Container>
            </>
          ) : (
            <CircularProgress />
          )
        }
      </>
    )
}
