import request from './request'

class createMealRequest extends request {
    private userEmail: string;
    private recipeName: string;
    private recipeIngredients: string[];
    private directions: string;
    private filters: string[];
    private calories: string;
  
    constructor(userEmail: string, recipeName: string, recipeIngredients: string[], directions: string, filters: string[], calories: string) {
        super();
        this.userEmail = userEmail;
        this.recipeName = recipeName;
        this.recipeIngredients = recipeIngredients;
        this.directions = directions;
        this.filters = filters;
        this.calories = calories;
    }
}

export default createMealRequest;